<div class="flex flex-row space-x-2">
  <search-table-filter [formControl]="searchControl"></search-table-filter>

  <div [formGroup]="filterControl" class="flex flex-row space-x-2">
    <mat-slide-toggle
      formControlName="onlyOwned"
      *ngxPermissionsOnly="contactsPermission.showOnlyOwned"
    >
      {{ 'common.SHOW_ONLY_OWNED' | transloco }}</mat-slide-toggle
    >
  </div>
</div>
