import { Injectable } from '@angular/core';
import { LimitReport } from '@backoffice-frontend/limit-report/domain';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { LimitReportPowerService } from './limit-report.service';

@Injectable({ providedIn: 'root' })
export class LimitReportPowerListFacade
  implements EditSearchDataService<LimitReport> {
  constructor(protected service: LimitReportPowerService) {
  }

  public getAll$(
    params: DataTableParameters
  ): Observable<PaginationResponse<LimitReport>> {
    return this.service.getAll$(params);
  }

  public getById$(id: ID): Observable<LimitReport> {
    return this.service.getById$(id);
  }

  add$(entity: LimitReport): Partial<Observable<boolean | LimitReport | ID>> {
    throw new Error('Method not implemented.');
  }

  update$(
    entity: LimitReport
  ): Partial<Observable<boolean | LimitReport | ID>> {
    throw new Error('Method not implemented.');
  }

  delete$(id: ID): Partial<Observable<boolean | void>> {
    return this.service.delete$(id);
  }
}
