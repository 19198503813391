import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { Route, RouterModule } from '@angular/router';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { UiMatTableModule } from '@clean-code/shared/components/ui-mat-table';
import { PageContainerModule } from '@clean-code/shared/components/ui-page-container';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LimitReportPowerSearchComponent } from '../limit-report-power-search/limit-report-power-search.component';
import { LimitReportPowerListComponent } from './limit-report-list.component';

const routes: Route[] = [
  {
    path: '',
    component: LimitReportPowerListComponent,
    children: [
      {
        path: 'preview/:id',
        loadChildren: () =>
          import(
            '../limit-report-list-preview/limit-report-list-preview.module'
          ).then((mod) => mod.LimitReportPowerListPreviewModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    MatFormFieldModule,
    MatDividerModule,
    MatRadioModule,

    TranslocoModule,

    FormContainerModule,
    PageContainerModule,
    UiMatTableModule,
    NgxPermissionsModule,
    LimitReportPowerSearchComponent,
  ],
  declarations: [LimitReportPowerListComponent],
  exports: [LimitReportPowerListComponent],
})
export class LimitReportPowerListModule {}
