import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ContactsPermissions } from '@backoffice/shared/bo/util-permissions';
import {
  BaseTableFilterLegacy,
  SearchTableFilterComponent,
} from '@clean-code/shared/components/ui-data-table';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  standalone: true,
  selector: 'limit-report-power-search',
  templateUrl: './limit-report-power-search.component.html',
  imports: [
    ReactiveFormsModule,

    MatSlideToggleModule,
    NgxPermissionsModule,

    TranslocoModule,

    SearchTableFilterComponent,
  ],
})
export class LimitReportPowerSearchComponent extends BaseTableFilterLegacy {
  public override filterControl = new UntypedFormGroup({
    onlyOwned: new UntypedFormControl(false),
  });

  public contactsPermission = ContactsPermissions;

  // constructor(
  //   service: LimitReportPowerListFacade,
  // ) {
  //   super(service);
  // }
}
